import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";

// markup
const ServicePage = () => {
  return (
    <Layout pageClass="services-page">
        <Helmet>
            <title>MatteCurve - Services | Top Web and Mobile App Development Company In India</title>
            <meta name="description" content="Hire top Dedicated Node.js developers in India at a highly attractive price for full-stack NodeJS to develop custom Web, iOS, and Android apps. Hire now!" />
            <meta name="keywords" content="Web Development, Mobile App Development, React, Node.js, Angular.js, Remote Developers" />
        </Helmet>
        <Header></Header>
        <main>
        <div className="hero-wrapper container-fluid">
            <h1 className="animate__animated animate__fadeInUp">Services</h1>
            <br/>
            <p className="animate__animated animate__fadeInUp">
                MatteCurve Technologies partners with clients from startups to Fortune 500s to create end to end solutions. 
                Focusing on JavaScript as our core technology, we utilize React.js, React Native, Node.js, Angular.js to design and build web apps, mobile apps, cloud services, open source software, and more.
                At MatteCurve, we offer below services:
            </p>
            <div className="animate__animated animate__fadeInUp">
                <h4>Software Product Engineering</h4>
                <p>
                We help our clients build a web presence, mobile presence 
                and connect with their customers. 
                Our software product engineering services include web development, 
                mobile app development, and MVP development.
                </p>
            </div>
            <br/>
            <div className="animate__animated animate__fadeInUp">
                <h4>Application Services</h4>
                <p>
                We help our clients expand their existing teams to speed up the development and launch sooner.
                Our experienced team can do long-term maintenance for your applications.
                </p>
            </div>
            <br/>
            <div className="animate__animated animate__fadeInUp">
                <h4>Digital Growth</h4>
                <p>
                With our digital marketing and search engine optimization expertise, our customers grow exponentially.
                </p>
            </div>
            <br/>
            {/* <div className="animate__animated animate__fadeInUp">
                <h4>MVP and Prototype Development</h4>
                <p>
                    An MVP is a lightweight early-version of your product. We can build quick MVP so that you will be able to get early feedbacks with least effort and validate your idea.
                </p>
            </div>
            <br/> */}
        </div>
        </main>
        <Footer></Footer>
    </Layout>
  )
}

export default ServicePage;
